<template>
    <div class="clue-list-index">
        <navigationBar />

        <el-row :gutter="20">
            <el-col :span="12">
                <div class="block">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <span>工单详情信息</span>
                        </div>
                        <el-form label-width="40%" :model="workOrder">
                            <el-form-item label="工单标题：">{{workOrder.title}}</el-form-item>
                            <el-form-item label="创建工单人姓名：">{{workOrder.user_name}}</el-form-item>
                            <el-form-item label="工单类型：">{{workOrder.workType_str}}</el-form-item>
                            <el-form-item label="紧急级别：">
                                <template scope="scope">
                                    <span
                                        style="color: #ff0000"
                                        v-if="workOrder.work_level_str=='普通'"
                                    >{{workOrder.work_level_str}}</span>
                                    <span
                                        style="color: #67C23A"
                                        v-if="workOrder.work_level_str=='特急'"
                                    >{{workOrder.work_level_str}}</span>
                                    <span
                                        style="color: #E6A23C"
                                        v-if="workOrder.work_level_str=='加急'"
                                    >{{workOrder.work_level_str}}</span>
                                </template>
                            </el-form-item>
                            <el-form-item label="工单状态：">
                                <template scope="scope">
                                    <span
                                        style="color: #ff0000"
                                        v-if="workOrder.work_status_str=='待处理'"
                                    >{{workOrder.work_status_str}}</span>
                                    <span
                                        style="color: #67C23A"
                                        v-if="workOrder.work_status_str=='已完结'"
                                    >{{workOrder.work_status_str}}</span>
                                    <span
                                        style="color: #E6A23C"
                                        v-if="workOrder.work_status_str=='转发中'"
                                    >{{workOrder.work_status_str}}</span>
                                </template>
                            </el-form-item>
                            <el-form-item label="创建时间：">{{workOrder.create_time}}</el-form-item>
                            <el-form-item label="图片：">
                                <div class="demo-image__preview" v-if="imgList!=''">
                                    <template v-for="item in this.imgList">
                                        <el-image
                                            style="width: 100px; height: 100px"
                                            :src="item"
                                            fit="fill"
                                            :preview-src-list="imgList"
                                        ></el-image>
                                    </template>
                                </div>
                                <div v-if="imgList == ''">
                                    <span style="color: red">此工单没有上传图片</span>
                                </div>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </div>
            </el-col>

            <el-col :span="12">
                <div class="block">
                    <el-card class="box-card" shadow="always">
                        <div slot="header">
                            <span>工单流转记录</span>
                        </div>
                        <el-timeline>
                            <el-timeline-item
                                v-for="activity in work_record_list"
                                :color="activity.color"
                            >
                                <el-card>
                                    <p>内容：{{activity.content}}</p>
                                    <p>处理人：{{activity.hand_name}}</p>
                                    <p>处理时间：{{activity.timestamp}}</p>

                                    <div class="demo-image__preview" v-if="activity.img_url!=''">
                                        <el-image
                                            v-for="img in activity.img_url"
                                            style="width: 100px; height: 100px"
                                            :src="img"
                                            :preview-src-list="activity.img_url"
                                        ></el-image>
                                    </div>
                                </el-card>
                            </el-timeline-item>
                        </el-timeline>
                    </el-card>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import navigationBar from "@/components/navigationBar";
import {
    findWorkOrderById,
    findWorkRecordList,
    findAllWorkOderPage,
    findWorkRecordByhandId,
    getSysDictionaryList,
} from "@/api/getBaseData";
import cookies from "@/utils/cookies/index.js";
export default {
    name: "page",
    data() {
        return {
            workOrder: {},
            imgList: [],
            listLoading: false,

            // 工单紧急级别字典表
            work_level_list: [],

            // 工单状态字典表
            work_status_list: [],

            // 工单类型字典表
            org_type_list: [],

            work_record_list: [],
        };
    },
    mounted() {
        this.findWorkOrderById();
        this.findWorkRecord();
        this.getWorkLevelDic();
        this.getWorkStatusDic();
        this.getorgTypeDic();
    },
    components: {
        navigationBar,
    },
    methods: {
        findWorkOrderById() {
            var id = this.$route.query.id;
            console.log("工单id：", id);
            let para = {
                id: id,
            };
            findAllWorkOderPage(para).then((res) => {
                this.workOrder = res.data.list[0];
                let imgurl = this.workOrder.img_url;
                if (imgurl != null) {
                    this.imgList = imgurl.split(",");
                }
            });
        },
        //流程
        findWorkRecord(work_id) {
            var id = this.$route.query.id;
            console.log("KKKK", id);
            let para = {
                work_id: id,
            };
            findWorkRecordList(para).then((res) => {
                this.work_record_list = res.data;
                var list = [];
                list = res.data;
                let act_list = [];
                for (let i = 0; i < list.length; i++) {
                    let a = {};
                    a.content = list[i].opinion;
                    a.timestamp = list[i].create_time;
                    a.img_url = [];
                    if (list[i].img_url != null) {
                        a.img_url = list[i].img_url.split(",");
                    }
                    if (list[i].status == 2) {
                        a.color = "#ff0000";
                    } else if (list[i].status == 3) {
                        a.color = "#E6A23C";
                    }
                    a.size = "";
                    a.icon = "";
                    a.hand_name = list[i].hand_name;
                    act_list.push(a);
                }
                //
                this.work_record_list = act_list;
            });
        },
        // 获取工单类型字典
        getorgTypeDic: function () {
            let para = {
                classifyCode: "workType",
            };
            getSysDictionaryList(para).then((result) => {
                this.org_type_list = result.data;
                console.log("工单类型字典列表", this.org_type_list);
            });
        },

        // 获取紧急级别字典
        getWorkLevelDic: function () {
            let para = {
                classifyCode: "workLevel",
            };
            getSysDictionaryList(para).then((result) => {
                this.work_level_list = result.data;
                console.log("紧急级别字典列表", this.work_level_list);
            });
        },

        // 获取工单状态字典
        getWorkStatusDic: function () {
            let para = {
                classifyCode: "workStatus",
            };
            getSysDictionaryList(para).then((result) => {
                this.work_status_list = result.data;
                console.log("字典列表", this.work_level_list);
            });
        },
    },
};
</script>
<style scoped>
</style>