import request from '@/plugin/axios/base'


export function getTestListInfoPage(data) {
  return request({
      url: '/test/getSysDictionaryPage',
      method: 'get',
      params:data
  });
}

// 测试二维码
export function testDemo(data) {
    return request({
        url: '/openApi/testDemo',
        method: 'POST',
        params:data,
        responseType:'blob'
    });
  }
 
// 查询字典表
export function getSysDictionaryList(data) {
  return request({
      url: '/test/getSysDictionaryList',
      method: 'get',
      params:data
  });
}

// 查询字典表
export function getSysDictionaryPage(data) {
    return request({
        url: '/test/getSysDictionaryPage',
        method: 'POST',
        params:data
    });
}

// 添加或编辑字典表
export function addOrEditSysDictionary(data) {
    return request({
        url: '/test/addOrEditSysDictionary',
        method: 'POST',
        params:data
    });
}delSysDictionary

// 删除字典（真删除）
export function delSysDictionary(data) {
    return request({
        url: '/test/delSysDictionary',
        method: 'POST',
        params:data
    });
}


// 查询所有工单
export function findAllWorkOderPage(data) {
    return request({
        url: '/workOder/findAllWorkOderPage',
        method: 'GET',
        params:data
    })
}

// 添加工单
export function addWorkOder(data) {
    return request({
        url: '/workOder/addWorkOder',
        method: 'POST',
        params:data
    })
}

// 根据id查询工单
export function findWorkOrderById(data) {
    return request({
        url: '/workOder/findWorkOrderById',
        method: 'GET',
        params:data
    })
}

// 根据id查询工单记录
export function findWorkOrderByHandIdAndStatus(data) {
    return request({
        url: '/workOder/findWorkOrderByHandIdAndStatus',
        method: 'GET',
        params:data
    })
}

// 处理工单 同意、拒绝或转发
export function handleWorkOrder(data) {
    return request({
        url: '/workOder/handleWorkOrder',
        method: 'POST',
        params:data
    })
}

// 文件上传
export function fileUpload(fileobj){
    // 上传文件对象 名称file与后台控制器参数要一致
    let param = {
        file:fileobj.file,
        bucketName:"dh-file-gx",
        fileName:"gd-workOrder"
    }

    return request({
        method: 'post',
        // 上传地址
        url: 'http://114.55.126.232:7011/oss/uploadFile',
        // 定义上传头
        headers: { 'content-type':'application/x-www-form-urlencoded'},
        // 接受文件格式
        accept: {
            title: 'Images',
            extensions: 'gif,jpg,jpeg,bmp,png',
            mimeTypes: 'image/*'
        },
        data: param

    });
}
// 查询工单审批流程
export function findWorkRecordList(data) {
    return request({
        url: '/workOder/findWorkRecordList',
        method: 'GET',
        params:data
    })
}

// 编辑工单
export function updateWorkOrder(data) {
    return request({
        url: '/workOder/updateWorkOrder',
        method: 'POST',
        params:data
    })
}

// 删除工单
export function delWorkOrder(data) {
    return request({
        url: '/workOder/delWorkOrder',
        method: 'POST',
        params:data
    })
}

// 查询报表
export function findWorkingTime(data) {
    return request({
        url: '/openApi/findWorkingTime',
        method: 'POST',
        params:data
    })
}

// 抽奖-查询用户
export function getAllLotteryUserPage(data) {
    return request({
        url: '/lottery/getAllLotteryUserPage',
        method: 'POST',
        params:data
    })
}
// 抽奖-编辑用户
export function editLotteryUser(data) {
    return request({
        url: '/lottery/editLotteryUser',
        method: 'POST',
        params:data
    })
}