<template>
	<div>
		<div class="navigation" style="width:97.2%;" v-if="this.$store.state.dh.collapseState.isCollapse">
			<div class="float">
				<div class="title">
					<img src="/static/img/return.png" @click="goBack()" id="go-back" v-if="this.routerList.length > 1">
					<template v-for="(item,index) in routerList">
						<div v-if="index == 0" class="top"><h3>{{item.name}}</h3></div>
						<span to @click="backToPageIndex(index)" v-else class="other">/&nbsp;&nbsp;{{item.name}}</span>
					</template>
				</div>
				<div class="button">
					<template v-for="(item,index) in buttonList">
						<div class="child" v-bind:style="{backgroundColor:item.backgroundColor}" @click="selectClick(index)">
							<img v-bind:src="item.icon">
							<div> {{item.text}}</div>
						</div>
					</template>
				</div>
			</div>
		</div>

		<div class="navigation" style="width:88.5%;" v-if="!this.$store.state.dh.collapseState.isCollapse">
			<div class="float">
				<div class="title">
					<img src="/static/img/return.png" @click="goBack()" id="go-back" v-if="this.routerList.length > 1">
					<template v-for="(item,index) in routerList">
						<div v-if="index == 0" class="top"><h3>{{item.name}}</h3></div>
						<span to @click="backToPageIndex(index)" v-else class="other">/&nbsp;&nbsp;{{item.name}}</span>
					</template>
				</div>
				 <div class="button">
					<template v-for="(item,index) in buttonList">
						<div class="child" v-bind:style="{backgroundColor:item.backgroundColor}" @click="selectClick(index)">
							<img v-bind:src="item.icon">
							<div> {{item.text}}</div>
						</div>
					</template>
				</div>
			</div>
		</div>
		<div style="padding-top:3.5rem;height: 1.5rem;">&nbsp;</div>
	</div>

</template>

<script>
	export default{
		name:'navigation-bar',
		props:['buttonList','doBack'],
		data(){
			return {
				routerList:this.$router.history.current.meta.breadcrumb
			}
		},
		methods:{
			selectClick:function(index){
				this.$emit("buttonClickEmit" + index);//自定义事件  传递值“子向父组件传值”
		 
			},
			goBack:function(){
				if(this.doBack){
					this.$emit("clickBackInfoShow",true);
				}else{
					this.$router.back();
				}
			},
			backToPageIndex:function(index){
				if(this.routerList.length-1!=index){
				this.$router.go(-(this.routerList.length-(index+1)));
				}			
			}
		}
	}
</script>

<style scoped lang="scss">
	.navigation{
		
		height:1.5rem;
		position: fixed;
		z-index: 999;
		// width: calc(100% - 12rem);
		background-image: linear-gradient(to bottom, #3C92FF 35%, #F6F7FB 0);
		margin:0 -1.5rem;
		.float{
			height:3.5rem;
			margin:0 1.5rem;
			padding:0 1rem;
			border-radius:0.25rem;
			background-color: #fff;
			display:flex;
			justify-content:space-between;
			align-items:center;
			.title{
				font-size: 1.2rem;
				font-weight: 500;
				display:flex;
				align-items:center;
				img{
					width:1.6rem;
					height:1.6rem;
					margin-right:1rem;
					&:hover{
				        content: url(/static/img/return-active.png) ;
				        cursor: pointer;
				    }
				}
				.top{
					color:#21293B;
				}
				.other{
					margin-left:10px;
					color:#5C6684;
				}
			}
			.button{
				font-size: 0.8rem;
				font-weight: 400;
				display: flex;
				color:#fff;
				.child{
					height:1.8rem;
					line-height: 1.8rem;
					margin-left:1.5rem;
					display: flex;
					align-items:center;
					padding:0px 0.5rem;
					border-radius:.15rem;
					&:hover{
						cursor:pointer;
					}
				}
				img{
					width:0.8rem;
					height:0.8rem;
					margin-right:0.5rem;
				}
			}
		}
	}
</style>